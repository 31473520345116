<template>
  <div class="statistic">
    <v-row v-if="$vuetify.breakpoint.smAndUp" >
      <v-col cols="3">
        <p style="margin:60px 0px 0px 0px;font-size:32px;">
          統計分析
        </p>
        <v-tabs
          v-model="tab"
          color="#4DBCB8"
        >
          <v-tab
            v-for="type in types"
            :key="type.name"
          >
            {{ type.name }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dateToNow"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              style="margin-top:52px;"
              v-model="dateRangeText"
              label="選擇期間"
              prepend-icon="mdi-calendar"
              readonly
              color="#4DBCB8"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateToNow"
            color="#4DBCB8"
            range
            no-title
            scrollable
            locale="zhHant"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="#4DBCB8"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="#4DBCB8"
              @click="getTasks"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <!-- <v-text-field
          v-model="tableSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          style="padding:0px 0px 10px 0px"
        ></v-text-field> -->
        <v-menu
          v-model="searchMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          offset-y
          transition="scale-transition"
          ref="searchMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="tableSearch"
              :append-icon="searchMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
              prepend-icon="mdi-magnify"
              label="現有表單搜索"
              single-line
              hide-details
              style="padding:0px 0px 10px 0px"
              @click:append="toggleMenu"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-card style="padding-top:40px;">
            <v-row v-for="search in proSearch" :key="search.label">
              <v-spacer />
              <v-col cols="3" style="padding:0px;">
                <div style="margin-top:10px;">{{ search.label }}</div>
              </v-col>
              <v-col cols="7" style="padding:0px;">
                <v-text-field
                  :placeholder="search.placeholder"
                  v-model="search.value"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="3" style="padding:0px;">
                <v-select
                  style="width:70px;margin-top:-10px;"
                  v-model="field"
                  item-text="label"
                  item-value="label"
                  return-object
                  :items="fieldSearch"
                  @change="clearField"
                >
                </v-select>
              </v-col>
              <v-col cols="7" style="padding:0px;">
                <v-text-field
                  v-if="field.label === '電話'"
                  :placeholder="fieldSearch[0].placeholder"
                  v-model="fieldSearch[0].value"
                  dense
                  outlined
                >
                </v-text-field>
                <v-text-field
                  v-else
                  :placeholder="fieldSearch[1].placeholder"
                  v-model="fieldSearch[1].value"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-card-actions style="text-align:center;">
              <v-btn
                style="width:200px;margin:auto;height:50px;font-size:16px;"
                color="grey"
                outlined
                @click="clear">
                重設
              </v-btn>
              <v-btn
                style="width:200px;margin:auto;height:50px;color:white;font-size:16px;"
                color="#4DBCB8"
                @click="detailSearch">
                搜尋
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-else >
      <v-col cols="12" style="text-align:center">
        <p style="margin:30px auto 0px auto;font-size:32px;">
          統計分析
        </p>
      </v-col>
      <v-col cols="12">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dateToNow"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              style="margin-top:22px;"
              v-model="dateRangeText"
              label="選擇期間"
              prepend-icon="mdi-calendar"
              readonly
              color="#4DBCB8"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateToNow"
            color="#4DBCB8"
            range
            no-title
            scrollable
            locale="zhHant"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="#4DBCB8"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="#4DBCB8"
              @click="getTasks"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-text-field
          v-model="tableSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          style="padding:0px 0px 10px 0px"
        ></v-text-field>
        <v-tabs
          style="margin:auto;"
          v-model="tab"
          color="#4DBCB8"
        >
          <v-tab
            v-for="type in types"
            :key="type.name"
          >
            {{ type.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="type in types"
        :key="type.name"
      >
        <v-row v-if="type.name === '人員'">
          <v-col cols="12">
            <div v-if="firstTime" style="text-align:center;margin-top:30px;">
              <p>請選擇查詢期間</p>
            </div>
            <v-data-table
              :items-per-page="-1"
              height="500"
              :headers="headers"
              :items="wanted"
              :search="tableSearch"
              sort-by="id"
              :sort-desc="true"
              group-by="region"
              class="elevation-1"
              hide-default-footer
              :loading="wantLoading"
              fixed-header
              loading-text="資料讀取中... 請稍候"
              @click:row="goDetail"
            >
              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item.plan="{ item }">
                {{ (item.plan).toFixed(1) }}
              </template>
              <template v-slot:item.actual="{ item }">
                {{ (item.actual).toFixed(1) }}
              </template>
              <template v-slot:item.diff="{ item }">
                {{ (item.plan.toFixed(1) - item.actual.toFixed(1)).toFixed(1) }}
              </template>
              <template v-slot:item.workday="{ item }">
                {{ item.workDay.length }}
              </template>
              <template v-slot:item.score="{ item }">
                {{ item.score }}
              </template>
              <template v-slot:item.holiday="{ item }">
                {{ duringDays - item.workDay.length }}
              </template>
              <template v-slot:item.imgLack="{ item }">
                {{item.imgLack}}
              </template>
              <template v-slot:body.append="{ items }">
                <tr>
                  <td style="text-align:center;"></td>
                  <td style="text-align:right;">{{ calculateSum(items, 'plan') }}</td>
                  <td style="text-align:right;">{{ calculateSum(items, 'actual') }}</td>
                  <td style="text-align:center;"></td>
                  <td style="text-align:center;"></td>
                  <td style="text-align:center;"></td>
                  <!-- <td style="text-align:right;">{{ calculateSum(items, 'regular') }}</td>
                  <td style="text-align:right;">{{ calculateSum(items, 'overtime') }}</td> -->
                  <td style="text-align:right;">{{ calculateSum(items, 'taskSum') }}</td>
                  <td style="text-align:center;"></td>
                  <td style="text-align:center;"></td>
                  <td style="text-align:right;">
                    {{ calculateSum(items, 'score') }}
                  </td>
                  <td style="text-align:center;"></td>
                  <td style="text-align:right;">
                    <!--{{ calculateSum(items, 'imgScore') }}-->
                  </td>
                  <td style="text-align:center;"></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-data-table
              :headers="taskHeaders"
              :items="tab === 0 ? tasks : filterTasks"
              :search="tableSearch"
              sort-by="id"
              :sort-desc="true"
              :items-per-page="10"
              @click:row="getTaskDetail"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    :class="{ 'alert': item.images === undefined
                      || item.imgReuquire > item.images.length }"
                    @click="getTaskDetail(item)"
                  >
                    <td>{{ item.clientName }}</td>
                    <td>{{ item.region }}</td>
                    <td>{{ item.clientTel }}</td>
                    <td>{{ item.address }}</td>
                    <td>{{ (new Date(item.start)).toLocaleString() }}</td>
                    <td>{{ new Date(item.end).toLocaleString() }}</td>
                    <td>
                      <span v-for="it in item.member" :key="it.name">{{it.name}}</span>
                    </td>
                    <td>
                      <span v-for="ta in item.task" :key="ta.name">{{ta.name}}</span>
                    </td>
                    <td style="text-align:center;">
                      <span v-if="item.duration === undefined">-</span>
                      <span v-else>{{(item.duration / 3600000).toFixed(1)}}</span>
                    </td>
                    <td style="text-align:center;">
                      <span v-if="item.modifyScore === undefined">
                        {{item.totalScore}}
                      </span>
                      <span v-else>
                        {{item.totalScore + Number(item.modifyScore)}}
                      </span>
                    </td>
                    <td style="text-align:right;padding-right:4px;">
                      <span v-if="item.imgScore === undefined">0</span>
                      <span v-else>{{ item.imgScore }}</span>
                    </td>
                    <td style="text-align:right;">{{ item.imgReuquire }}</td>
                    <td style="text-align:right;padding-right:4px;">
                      <span v-if="item.images === undefined">0</span>
                      <span v-else>{{ item.images.length }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="dialog"
      width="960"
    >
      <v-card style="padding:20px;" v-if="$store.state.taskDetail != undefined">
        <v-row>
          <v-col cols="4">
            <span style="font-size:20px">任務詳情</span>
            <v-icon
              @click="goTask"
              style="margin-left:10px;cursor:pointer;">
              mdi-open-in-new
            </v-icon>
          </v-col>
          <v-col cols="8">
            <div style="text-align:right;">
              <v-icon @click="dialog = false">
                mdi-close
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-row v-for="taskDetail in taskHead" :key="taskDetail.label">
              <v-spacer />
              <v-col cols="1" style="padding:0px">
                <v-icon style="color:#4DBCB8">
                  {{taskDetail.icon}}
                </v-icon>
              </v-col>
              <v-col cols="3" style="padding:0px">
                {{ taskDetail.label }}
              </v-col>
              <v-col cols="7" style="padding:0px">
                <p v-if="taskDetail.value === 'member'">
                  <span
                    :key="ta.name"
                    v-for="(ta, index) in $store.state.taskDetail[taskDetail.value]">
                    {{ ta.name }}
                    <span v-if="index !== $store.state.taskDetail.task.length - 1">、</span>
                  </span>
                  <!-- {{ $store.state.taskDetail[taskDetail.value][0].name }} -->
                </p>
                <p v-else-if="taskDetail.value === 'task'">
                  <span
                    :key="ta.name"
                    v-for="(ta, index) in $store.state.taskDetail[taskDetail.value]">
                    {{ ta.name }}
                    <span v-if="index !== $store.state.taskDetail.task.length - 1">、</span>
                  </span>
                </p>
                <div v-else-if="taskDetail.value === 'clientInfo'">
                  {{ $store.state.taskDetail.clientName }}-{{ $store.state.taskDetail.clientId }}
                  <br>
                  {{ $store.state.taskDetail.clientTel }}
                </div>
                <p v-else-if="taskDetail.value === 'start'||taskDetail.value === 'end'">
                  {{ new Date($store.state.taskDetail[taskDetail.value]) }}
                </p>
                <p v-else-if="taskDetail.value === 'traffic'">
                  0
                </p>
                <p v-else>{{ $store.state.taskDetail[taskDetail.value] }}</p>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="11" v-if="$store.state.taskDetail.images !== undefined">
                <v-row v-if="$store.state.taskDetail.images.length > 0">
                  <v-col cols="3" v-for="image in $store.state.taskDetail.images" :key="image">
                    <v-img
                      style="width:100%;cursor:pointer;"
                      :src="`https://storage.googleapis.com/shihleicloud/${$store.state.taskDetail.id}/${image}.jpg`"
                      @click="goPic(`https://storage.googleapis.com/shihleicloud/${$store.state.taskDetail.id}/${image}.jpg`)"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="11">
                <div v-if="$store.state.taskDetail.arrived !== undefined">
                  到達時間：{{new Date($store.state.taskDetail.arrived.time)}}
                  <v-icon
                    @click="goMap($store.state.taskDetail.arrived)">
                    mdi-map-marker
                  </v-icon>
                </div>
                <div v-if="$store.state.taskDetail.finished !== undefined">
                  完成時間：{{new Date($store.state.taskDetail.finished.time)}}
                  <v-icon
                    @click="goMap($store.state.taskDetail.finished)">
                    mdi-map-marker
                  </v-icon>
                </div>
                <div v-if="$store.state.taskDetail.comments !== undefined" style="opacity:0.8">
                  備註：{{$store.state.taskDetail.comments}}
                </div>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row style="margin-top:0px;">
              <v-spacer />
              <v-col cols="11" style="padding:0px 12px">
                <p style="margin-bottom:0px;">圖片(標準安裝作業)主管評分：
                  {{$store.state.taskDetail.imgScore}}
                  分，
                  {{$store.state.taskDetail.imgComments}}
                </p>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row style="margin-top:0px;">
              <v-spacer />
              <v-col cols="11">
                <span>預計總時程
                  {{$store.state.taskDetail.totalPlaned}}
                  分鐘，總計積分
                  {{$store.state.taskDetail.totalScore}}
                </span>
                <span v-if="$store.state.taskDetail.modifyScore !== undefined">
                  ，調整後積分為
                  {{
                    $store.state.taskDetail.totalScore + Number($store.state.taskDetail.modifyScore)
                  }}
                </span>
              </v-col>
              <v-spacer />
            </v-row>
          </v-col>
          <v-col cols="1" style="text-align:right;">
            <v-divider
              vertical
            ></v-divider>
          </v-col>
          <v-col cols="3">
            <div style="height:420px;overflow:scroll;">
            <v-stepper
              v-if="$store.state.taskDetail.history !== undefined"
              vertical
              elevation="0"
              v-model="$store.state.taskDetail.history.length"
            >
            <!-- eslint-disable -->
            <template
              v-for="history in $store.state.taskDetail.history"
              >
              <v-stepper-step
                color="#4DBCB8"
                :complete="$store.state.taskDetail.history.length > 1"
                step="1"
              >
                {{history.name}}
                <p style="margin:4px 0px 0px 0px;color:grey">
                  {{history.process}}
                </p>
                <small>
                  {{(new Date(history.time)).getFullYear()}}/
                  {{((new Date(history.time)).getMonth() + 1).toString().padStart(2, '0')}}/
                  {{((new Date(history.time)).getDate()).toString().padStart(2, '0')}}
                  {{((new Date(history.time)).getHours()).toString().padStart(2, '0')}} :
                  {{((new Date(history.time)).getMinutes()).toString().padStart(2, '0')}}
                </small>
              </v-stepper-step>

              <v-stepper-content step="1">
              </v-stepper-content>
            </template>
            </v-stepper>
            </div>
            <!-- eslint-enable -->
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../api/statistic';
// @ is an alias to /src

export default {
  name: 'Permission',
  components: {

  },
  data: () => ({
    searchMenu: false,
    duringDays: 0,
    firstTime: true,
    wantLoading: false,
    fakeStep: 1,
    proSearch: [
      {
        label: '單據編號',
        placeholder: '請輸入單據編號',
        value: '',
      }, {
        label: '客戶編號',
        placeholder: '請輸入客戶編號',
        value: '',
      },
    ],
    field: {
      label: '電話',
      placeholder: '請輸入客戶電話',
      value: '',
    },
    fieldSearch: [
      {
        label: '電話',
        placeholder: '請輸入客戶電話',
        value: '',
      }, {
        label: '地址',
        placeholder: '請輸入施作地點(不用含城市與鄉鎮區)',
        value: '',
      },
    ],
    dialog: false,
    dateToNow: [],
    tableSearch: '',
    tab: 0,
    tasks: [],
    types: [
      {
        name: '人員',
      }, {
        name: '任務',
      },
    ],
    currentTime: new Date(),
    menu: false,
    wanted: [],
    workers: [],
    taskHead: [
      {
        icon: 'mdi-city',
        label: '區域',
        value: 'region',
      }, {
        icon: 'mdi-list-box-outline',
        label: '單據編號',
        value: 'sheetId',
      }, {
        icon: 'mdi-account-box-outline',
        label: '客戶資料',
        value: 'clientInfo',
      }, {
        icon: 'mdi-clipboard-outline',
        label: '任務',
        value: 'task',
      }, {
        icon: 'mdi-account',
        label: '人員',
        value: 'member',
      }, {
        icon: 'mdi-clock',
        label: '開始時間',
        value: 'start',
      }, {
        icon: '',
        label: '結束時間',
        value: 'end',
      }, {
        icon: 'mdi-map-marker',
        label: '地點',
        value: 'address',
      }, {
        icon: 'mdi-car-estate',
        label: '路程積分',
        value: 'traffic',
      },
    ],
    taskHeaders: [
      {
        text: '客戶名稱', value: 'clientName', width: 70, align: 'left', groupable: false,
      }, {
        text: '區域', value: 'region', width: 60, align: 'left', groupable: false,
      }, {
        text: '電話', value: 'clientTel', width: 70, align: 'left', groupable: false,
      }, {
        text: '地址', value: 'address', width: 100, align: 'left', groupable: false,
      }, {
        text: '開始時間', value: 'start', width: 70, align: 'left', groupable: false,
      }, {
        text: '完成時間', value: 'end', width: 70, align: 'left', groupable: false,
      }, {
        text: '施工師傅', value: 'member', width: 70, align: 'left', groupable: false,
      }, {
        text: '任務內容', value: 'task', width: 100, align: 'left', groupable: false,
      }, {
        text: '工作時數', value: 'duration', width: 70, align: 'right', groupable: false,
      }, {
        text: '累計積分', value: 'totalScore', width: 70, align: 'right', groupable: false,
      }, {
        text: '圖片分數', value: 'imgScore', width: 70, align: 'right', groupable: false,
      }, {
        text: '應拍照數', value: 'imgReuquire', width: 70, align: 'right', groupable: false,
      }, {
        text: '實拍照數', value: 'imgActual', width: 70, align: 'right', groupable: false,
      },
    ],
    headers: [
      {
        text: '姓名', value: 'name', align: 'center', groupable: false,
      }, {
        text: '區域', value: 'region', align: 'left', groupable: false,
      }, {
        text: '預計工時', value: 'plan', align: 'right', groupable: false,
      }, {
        text: '實際工時', value: 'actual', align: 'right', groupable: false,
      }, {
        text: '差異', value: 'diff', align: 'right', groupable: false,
      }, {
        text: '到班日數', value: 'workday', align: 'right', groupable: false,
      }, {
        text: '休班日數', value: 'holiday', align: 'right', groupable: false,
      }, /* {
        text: '上班時數', value: 'regular', align: 'right', groupable: false,
      }, {
        text: '加班時數', value: 'overtime', align: 'right', groupable: false,
      }, */{
        text: '任務數', value: 'taskSum', align: 'right', groupable: false,
      }, {
        text: '平均日任務', value: 'taskAverage', align: 'right', groupable: false,
      }, {
        text: '調整積分', value: 'totalModifyScore', align: 'right', groupable: false,
      }, {
        text: '累計積分', value: 'score', align: 'right', groupable: false,
      }, {
        text: '平均日積分', value: 'scoreAverage', align: 'right', groupable: false,
      }, {
        text: '主管評分', value: 'imgScoreAverage', align: 'right', groupable: false,
      }, {
        text: '照片缺漏', value: 'imgLack', align: 'right', groupable: false,
      },
    ],
    items: [],
    filterTasks: [],
  }),
  mounted() {
    // const year = (new Date()).getFullYear();
    // const month = ((new Date()).getMonth() + 1).toString().padStart(2, '0');
    // const day = (new Date()).getDate().toString().padStart(2, '0');
    // this.$set(this.dateToNow, 0, `${year}-${month}-01`);
    // this.$set(this.dateToNow, 1, `${year}-${month}-${day}`);
    this.listWorkers();
  },
  computed: {
    dateRangeText() {
      return this.dateToNow.join(' ~ ');
    },
  },
  methods: {
    handleTabChange() {
      console.log('do nothing', this.types[this.tab]);
    },
    goPic(url) {
      window.open(url);
    },
    clearField() {
      this.$set(this, 'fieldSearch', [
        {
          label: '電話',
          placeholder: '請輸入客戶電話',
          value: '',
        }, {
          label: '地址',
          placeholder: '請輸入施作地點(不用含城市與鄉鎮區)',
          value: '',
        },
      ]);
    },
    clear() {
      this.$set(this, 'fieldSearch', [
        {
          label: '電話',
          placeholder: '請輸入客戶電話',
          value: '',
        }, {
          label: '地址',
          placeholder: '請輸入施作地點(不用含城市與鄉鎮區)',
          value: '',
        },
      ]);
      this.$set(this, 'proSearch', [
        {
          label: '單據編號',
          placeholder: '請輸入單據編號',
          value: '',
        }, {
          label: '客戶編號',
          placeholder: '請輸入客戶編號',
          value: '',
        },
      ]);
    },
    detailSearch() {
      this.$set(this, 'filterTasks', []);
      this.tasks = [];
      api.detailSearch({
        sheetId: this.proSearch[0].value,
        clientId: this.proSearch[1].value,
        tel: this.fieldSearch[0].value,
        address: this.fieldSearch[1].value,
      })
        .then(async ({ data }) => {
          if (data.success) {
            this.tab = 1;
            this.tasks = data.tasks;
            this.$set(this, 'filterTasks', data.tasks);
            console.log('success', data, this.tasks);
            this.clear();
            this.searchMenu = false;
          }
        })
        .catch((error) => {
          console.log('err: ', error);
        });
    },
    toggleMenu() {
      this.searchMenu = !this.searchMenu;
      this.$refs.searchMenu.isActive = !this.$refs.searchMenu.isActive;
    },
    goTask() {
      const time = new Date(this.$store.state.taskDetail.start);
      const year = time.getFullYear();
      const month = (time.getMonth() + 1).toString().padStart(2, '0');
      const day = time.getDate().toString().padStart(2, '0');
      this.$router.push(`detail/${year}${month}${day}`);
      console.log(`${year}${month}${day}`);
    },
    goMap(geo) {
      window.open(`https://www.google.com.tw/maps/place/${geo.lat},${geo.lon}`);
    },
    calculateSum(arr, key) {
      const result = arr.reduce(
        (acc, obj) => Number(acc.toFixed(1)) + Number(obj[key].toFixed(1)),
        0,
      );
      return result;
    },
    getTaskDetail(item) {
      this.$store.state.taskDetail = item;
      this.dialog = true;
      console.log(item, 'IIIIIIIII');
    },
    async listWorkers() {
      await this.$set(this, 'wanted', []);
      await api.listWorkers()
        .then(async ({ data }) => {
          if (data.success) {
            data.list.forEach((ele) => {
              this.wanted.push({
                name: ele.name,
                region: ele.region,
                id: ele.id,
                plan: 0,
                actual: 0,
                diff: 0,
                regular: 0,
                overtime: 0,
                score: 0,
                imgScore: 0,
                taskSum: 0,
                taskAverage: 0,
                scoreAverage: 0,
                totalModifyScore: 0,
                imgScoreAverage: 0,
                workDay: [],
                imgLack: 0,
              });
              // console.log(ele, 'worrrrrkerrrrs');
            });
          } else {
            console.log('err:', data);
          }
        });
    },
    async getTasks() {
      await this.listWorkers();
      let first = null;
      let final = null;
      const check = this.dateToNow[0] > this.dateToNow[1];
      this.menu = false;
      this.wantLoading = true;
      this.$refs.menu.save(this.dateToNow);
      await api.getTasks({
        kind: this.types[this.tab],
        start: check ? this.dateToNow[1] : this.dateToNow[0],
        end: check ? this.dateToNow[0] : this.dateToNow[1],
      })
        .then(async ({ data }) => {
          if (check) {
            first = (new Date(this.dateToNow[1])).getTime();
            final = (new Date(this.dateToNow[0])).getTime();
          } else {
            first = (new Date(this.dateToNow[0])).getTime();
            final = (new Date(this.dateToNow[1])).getTime();
          }
          this.duringDays = ((final - first) / 86400000) + 1;
          this.firstTime = false;
          if (data.success) {
            this.wantLoading = false;
            await this.$set(this, 'tasks', data.tasks);
            console.log(data.tasks, '??????');
            data.tasks.forEach(async (ele) => {
              let amount = 0;
              await ele.task.forEach((e) => {
                amount += e.images.length;
              });
              await this.$set(ele, 'imgReuquire', amount);
              if (ele.address !== undefined) {
                const location = ele.location !== undefined ? ele.location : '';
                const dist = ele.dist !== undefined ? ele.dist : '';
                const address = ele.address !== undefined ? ele.address : '';
                this.$set(ele, 'address', `${location}${dist}${address}`);
              }
              if (this.types[this.tab].name !== '任務') {
                await ele.member.forEach(async (el) => {
                  // const found = this.workers.find((e) => e.name === el);
                  const found = this.wanted.find((e) => e.name === el.name);
                  found.plan += Number(ele.totalPlaned / 60);
                  if (ele.imgScore === undefined) {
                    found.imgScore += 0;
                    console.log('no img score');
                  } else {
                    found.imgScore += Number(ele.imgScore);
                  }
                  if (ele.modifyScore === undefined) {
                    found.score += Number(ele.totalScore);
                  } else {
                    found.score += Number(ele.totalScore) + Number(ele.modifyScore);
                    // if (found.totalModifyScore === undefined) {
                    //   this.$set(found, 'totalModifyScore', 0)
                    // } else {
                    //   found.totalModifyScore += Number(ele.modifyScore);
                    // }
                    found.totalModifyScore += Number(ele.modifyScore);
                  }
                  if (ele.duration !== undefined) {
                    found.actual += Number(Number(ele.duration / 3600000).toFixed(1));
                  }
                  found.taskSum += 1;
                  const year = (new Date(ele.start)).getFullYear();
                  const month = ((new Date(ele.start)).getMonth() + 1).toString().padStart(2, '0');
                  const day = (new Date(ele.start)).getDate().toString().padStart(2, '0');
                  const newday = await `${year}${month}${day}`;
                  if (!found.workDay.some((d) => d === newday)) {
                    await found.workDay.push(newday);
                  }
                  found.taskAverage = (found.taskSum / found.workDay.length).toFixed(2);
                  found.scoreAverage = (found.score / found.workDay.length).toFixed(2);
                  found.imgScoreAverage = (found.imgScore / found.taskSum).toFixed(2);
                  if (ele.images === undefined && ele.imgReuquire !== 0) {
                    found.imgLack += 1;
                  } else if (ele.imgReuquire > ele.images.length) {
                    found.imgLack += 1;
                  }
                });
              } else {
                this.$set(this, 'filterTasks', this.tasks);
              }
            });
            // this.$set(this, 'wanted', data.tasks);
            console.log(this.dateToNow, 'stassssssss');
          } else {
            console.log('err:', data);
          }
        });
    },
    search(dates) {
      this.$refs.menu.save(dates);
      console.log(dates, 'PPPPP');
      api.getStatistic({
        start: this.dateToNow[0],
        end: this.dateToNow[1],
      })
        .then(async ({ data }) => {
          if (data.success) {
            console.log(data, 'stassssssss');
          } else {
            console.log('err:', data);
          }
        });
    },
    goDetail(task) {
      this.tab = 1;
      this.filterTasks = this.tasks.filter((ele) => ele.member.some((el) => el.name === task.name));
      console.log(this.filterTasks, this.filterTasks.length);
    },
    resetTasks() {
      this.tab = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.alert {
  background-color: rgba(255, 0, 0, .3);
}
:deep th.text-left.sortable {
  padding: 0px 2px;
}
:deep th.text-center.sortable {
  padding: 0px 2px;
}
:deep th.text-right.sortable {
  padding: 0px 2px;
}
:deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
  padding: 0px 2px;
}
@media (min-width: 600px) {
  :deep table {
    table-layout: fixed;
  }
}
// :deep table {
//   table-layout: fixed;
// }
:deep td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
